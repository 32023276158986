@import './Draggable/index.css';
@import './Droppable/index.css';
@import './ErrorCell/index.css';
@import './Resizable/index.css';

.ory-cell-inline-left {
  float: left;
}

.ory-cell-inline-right {
  float: right;
}

.ory-cell-has-inline-neighbour {
  float: none;
  width: 100%;
}
.ory-cell-is-draft {
  opacity: 0.3;
  outline: 1px dashed black;
}

.ory-cell-focused {
  box-shadow: 0 0 1000px rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.ory-cell {
  position: relative;
}

.ory-cell-bring-to-front {
  z-index: 1;
}

.ory-cell-resizing-overlay:after {
  content: ' ';
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
