.ory-row-has-floating-children {
  display: block !important;
}

.ory-row-is-hovering-this:before {
  content: ' ';
  position: absolute;
  background: black;
  display: block;
  background: var(--darkBlack);
  z-index: 2;
}

.ory-row-is-hovering-above:before {
  top: 0;
  height: 4px;
  width: 100%;
}

.ory-row-is-hovering-below:before {
  bottom: 0;
  height: 4px;
  width: 100%;
}

.ory-row-is-hovering-left-of:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
}

.ory-row-is-hovering-right-of:before {
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px;
}
