.ory-cell-draggable {
  cursor: move;
  position: relative;
  transition: opacity 200ms ease;
  /* resolves an issue with drag and drop preview in chrome, see https://github.com/ory/editor/issues/180 */
  /* will-change: transform; */
}

.ory-cell-draggable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  cursor: move;
  border: 1px solid rgba(0, 0, 0, 0);
  transition: all ease 0.4s;
}

.ory-cell-draggable-overlay-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  cursor: move;
  background: var(--minBlack);
  content: '';
  z-index: 500;
}

.ory-cell-draggable-overlay-handle
  .ory-cell-draggable-overlay-handle-icon::before {
  top: -300%;
}

.ory-cell-draggable-overlay-handle .ory-cell-draggable-overlay-handle-icon {
  width: 70%;
  top: 50%;
}

.ory-cell-draggable-overlay-handle
  .ory-cell-draggable-overlay-handle-icon::after {
  top: 400%;
}

.ory-cell-draggable-overlay-handle
  .ory-cell-draggable-overlay-handle-icon::before,
.ory-cell-draggable-overlay-handle
  .ory-cell-draggable-overlay-handle-icon::after {
  width: 100%;
}

.ory-cell-draggable-overlay-handle .ory-cell-draggable-overlay-handle-icon,
.ory-cell-draggable-overlay-handle
  .ory-cell-draggable-overlay-handle-icon::before,
.ory-cell-draggable-overlay-handle
  .ory-cell-draggable-overlay-handle-icon::after {
  height: 2px;
  position: absolute;
  content: '';
  transform: translate(-50%, -50%);
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}

.ory-cell-draggable-overlay:hover {
  background: var(--minBlack);
  border: 1px solid var(--lightWhite);
  background-blend-mode: screen;
}

.ory-cell-draggable-overlay-description {
  text-align: center;
  display: block;
  color: rgba(0, 0, 0, 0.97);
  max-width: 100%;
}

.ory-cell-draggable-overlay-description span {
  opacity: 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.97);
  max-width: 90%;
  display: inline-block;
  padding: 8px 22px;
  margin: 0 auto;
  border-radius: 0 0 12px 12px;
  text-transform: uppercase;
  font-size: 75%;
  letter-spacing: 0.15em;
  background: rgba(255, 255, 255, 0);
  transition: all ease 0.4s;
}

.ory-cell-draggable-overlay:hover .ory-cell-draggable-overlay-description span {
  background: rgba(255, 255, 255, 0.95);
  opacity: 1;
}

.ory-cell-draggable-overlay.ory-cell-draggable-leaf {
  z-index: 3;
}

.ory-cell-draggable-inline-left.ory-cell-draggable-overlay,
.ory-cell-draggable-inline-right.ory-cell-draggable-overlay {
  float: left;
  z-index: 4;
}

.ory-cell-draggable-is-dragging {
  opacity: 0.4;
  outline: none;
}
