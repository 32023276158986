.ory-plugins-content-spacer {
  border: 1px dashed var(--minBlack);
  border-bottom: none;
  position: relative;
}

.ory-plugins-content-spacer-read-only {
  border-color: transparent !important;
}

.ory-plugins-content-spacer > .react-resizable > .react-resizable-handle:before,
.ory-plugins-content-spacer
  > .react-resizable
  > .react-resizable-handle:hover:before {
  content: ' ';
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
  right: 0;
  cursor: n-resize;
  line-height: 12px;
  font-size: 1.5em;
  height: 24px;
}
